import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import Link from 'next/link';

export interface Props {
    to: string;
}

const BackButton = ({ to }: Props) => {
    return (
        <div className="ml-4 hidden sm:block">
            <Link href={to}>
                <div className="flex size-10 items-center justify-center rounded-full text-gray-400 hover:bg-gray-100 hover:text-gray-600">
                    <ChevronLeftIcon className="-ml-[2px] size-6" />
                </div>
            </Link>
        </div>
    );
};

export default BackButton;
