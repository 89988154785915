export interface SubNavTabData {
    key: string;
    path: string;
}

export const enum TopLevelNavItem {
    home = 'home',
    dashboard = 'dashboard',
    achievements = 'achievements',
    settings = 'settings',
    referrals = 'referrals',
}

export const enum EditorLevelNavItem {
    editor = 'editor',
    integrations = 'integrations',
    analytics = 'analytics',
    metrics = 'metrics',
    publish = 'publish',
    crm = 'crm',
}
