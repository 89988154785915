import { MODAL_OPTIONS } from '@/app/modals/constants';
import { NAME } from '@/app/navigation/constants';
import { TRACKING_EVENTS } from '@/core/tracking/constants';

import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';

import CommandMenu from '@/app/cmdk/components/CommandMenu';
import { showModal } from '@/app/modals/models/modals';
import { Modals } from '@/app/modals/types';
import { Avatar } from '@/app/navigation/components/Navbar/Avatar';
import { BackButton } from '@/app/navigation/components/New/BackButton';
import { InviteButton } from '@/app/navigation/components/New/InviteButton';
import { NavbarNew } from '@/app/navigation/components/New/NavbarNew';
import { TopLevelNavNew } from '@/app/navigation/components/New/TopLevelNavNew';
import { WorkspaceSelector } from '@/app/navigation/components/New/WorkspaceSelector';
import { SupportMenu } from '@/app/support/components/SupportMenu';
import { useWorkspaces } from '@/app/workspaces/hooks/useWorkspaces';
import { WorkspaceSetting } from '@/app/workspaces/types';
import { useDefaultWorkspaceEnabled } from '@/core/loli-feature-flags/features/defaultWorkspace';
import { useAppDispatch } from '@/core/redux/hooks';
import { track } from '@/core/tracking';
import { CapabilitiesTarget, useUserCapabilities } from '@/hooks/useUserCapabilities';
import { LoadingSpinner } from '@/ui/components/LoadingSpinner';
import { cn } from '@/utils/cn';
import { getIsWorkspaceEnv } from '@/utils/environments';

import RootLayout from '../RootLayout';

import type { NavbarStyle } from '@/app/navigation/components/New/NavbarNew';
import type { ReactNode } from 'react';

export interface DefaultLayoutNewProps {
    children: ReactNode;
    style?: NavbarStyle;
    showWorkspaceSelector?: boolean;
    showTopLevelNavItems?: boolean;
    showWorkspaceHomeBackButton?: boolean;
    showInviteButton?: boolean;
    overrideWorkspaceHomeBackButtonLink?: string;
    isReactivation?: boolean;
    navbarTitle?: string;
}

const DefaultLayoutNew = ({
    children,
    isReactivation = false,
    style = 'solid-fill',
    showWorkspaceHomeBackButton,
    overrideWorkspaceHomeBackButtonLink,
    showWorkspaceSelector,
    showTopLevelNavItems,
    showInviteButton,
    navbarTitle,
}: DefaultLayoutNewProps) => {
    const dispatch = useAppDispatch();
    const isDefaultWorkspaceEnabled = useDefaultWorkspaceEnabled();

    const { events } = useRouter();
    const [navigating, setNavigating] = useState(false);
    const [navigatingDebounced, setNavigatingDebounced] = useState(false);

    const { canCreate } = useUserCapabilities(CapabilitiesTarget.Workspaces);
    const { activeWorkspace } = useWorkspaces();

    const { t } = useTranslation(NAME);

    const isWorkspaceEnv = getIsWorkspaceEnv();
    const shouldShowDefaultTopLevelNav =
        showTopLevelNavItems && !isReactivation && !isWorkspaceEnv && canCreate;

    const isDefaultWorkspace = activeWorkspace?.attributes?.default;

    const shouldShowInviteButton =
        showInviteButton && (!isDefaultWorkspace || isDefaultWorkspaceEnabled) && canCreate;

    const sharedTrackingData = useMemo(() => {
        return {
            currentWorkspace: {
                default: isDefaultWorkspace,
                custom: !isDefaultWorkspace,
            },
            whiteLabelView: isWorkspaceEnv,
        };
    }, [isDefaultWorkspace, isWorkspaceEnv]);

    const handleInvite = useCallback(() => {
        dispatch(
            showModal(
                Modals.WORKSPACE_SETTINGS,
                { activeTab: WorkspaceSetting.People },
                MODAL_OPTIONS[Modals.WORKSPACE_SETTINGS],
            ),
        );

        track(TRACKING_EVENTS.navigation.actions.inviteClicked, sharedTrackingData);
    }, [dispatch, sharedTrackingData]);

    useEffect(() => {
        const handleStart = () => {
            setNavigating(true);
        };
        const handleStop = () => {
            setNavigating(false);
        };

        events.on('routeChangeStart', handleStart);
        events.on('routeChangeComplete', handleStop);
        events.on('routeChangeError', handleStop);

        return () => {
            events.off('routeChangeStart', handleStart);
            events.off('routeChangeComplete', handleStop);
            events.off('routeChangeError', handleStop);
        };
    }, [events]);

    useEffect(() => {
        if (navigating) {
            const timeout = setTimeout(() => {
                setNavigatingDebounced(true);
            }, 500);

            return () => {
                clearTimeout(timeout);
            };
        } else {
            setNavigatingDebounced(false);
        }
    }, [navigating]);

    return (
        <RootLayout>
            <NavbarNew style={style}>
                <div className="flex flex-1 items-center">
                    {showWorkspaceHomeBackButton && (
                        <BackButton overrideBackLink={overrideWorkspaceHomeBackButtonLink} />
                    )}

                    {navbarTitle && (
                        <p className="px-4 text-sm font-semibold text-gray-600">{t(navbarTitle)}</p>
                    )}

                    {showWorkspaceSelector && <WorkspaceSelector />}
                </div>

                {shouldShowDefaultTopLevelNav && <TopLevelNavNew />}

                {/* TODO: Set gap and remove ml from Avatar after FF release */}
                <div className="flex flex-1 items-center justify-end">
                    <div className="flex items-center max-md:hidden">
                        {shouldShowInviteButton && <InviteButton onClick={handleInvite} />}
                    </div>

                    <Avatar />
                </div>
            </NavbarNew>

            <div
                className={cn(
                    'fixed inset-0 z-40 grid size-full place-items-center opacity-0 backdrop-blur-[2px] transition-all',
                    {
                        'opacity-100': navigatingDebounced,
                        'pointer-events-none': !navigatingDebounced,
                    },
                )}
            >
                {navigatingDebounced && <LoadingSpinner className="size-8" />}
            </div>

            {children}

            {!isReactivation && canCreate && <SupportMenu />}
            {!isReactivation && <CommandMenu />}
        </RootLayout>
    );
};

export default DefaultLayoutNew;
