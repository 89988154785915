import { useNavigationUpdatesEnabled } from '@/core/loli-feature-flags/features/navigationUpdates';
import DefaultLayoutNew from '@/ui/layouts/Default/DefaultLayoutNew';
import DefaultLayoutOld from '@/ui/layouts/Default/DefaultLayoutOld';

import type { DefaultLayoutNewProps } from '@/ui/layouts/Default/DefaultLayoutNew';
import type { DefaultLayoutOldProps } from '@/ui/layouts/Default/DefaultLayoutOld';

export type Props = DefaultLayoutOldProps | DefaultLayoutNewProps;

const DefaultLayout = (props: Props) => {
    const navbarUpdatesFeature = useNavigationUpdatesEnabled();

    if (navbarUpdatesFeature) {
        return <DefaultLayoutNew {...props} />;
    }

    return <DefaultLayoutOld {...props} />;
};

export default DefaultLayout;
