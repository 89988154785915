import { TopLevelNavItem } from '@/app/navigation/types';

import NavItem from '../NavItem';

export interface Props {
    activeTab?: TopLevelNavItem;
}

const TopLevelNav = ({ activeTab }: Props) => {
    return (
        <div className="flex justify-end">
            <NavItem link="/" type="home" active={activeTab === TopLevelNavItem.home} />
            <NavItem
                link="/dashboard"
                type="dashboard"
                active={activeTab === TopLevelNavItem.dashboard}
            />
            <NavItem
                link="/achievements"
                type="achievements"
                active={activeTab === TopLevelNavItem.achievements}
            />
            <NavItem
                link="/referrals"
                type="referrals"
                active={activeTab === TopLevelNavItem.referrals}
            />
        </div>
    );
};

export default TopLevelNav;
