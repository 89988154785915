import CommandMenu from '@/app/cmdk/components/CommandMenu';
import Navbar from '@/app/navigation/components/Navbar';
import { Avatar } from '@/app/navigation/components/Navbar/Avatar';
import BackButton from '@/app/navigation/components/Navbar/BackButton';
import TopLevelNav from '@/app/navigation/components/Navbar/TopLevelNav';
import { SupportMenu } from '@/app/support/components/SupportMenu';
import { CapabilitiesTarget, useUserCapabilities } from '@/hooks/useUserCapabilities';
import { getIsWorkspaceEnv } from '@/utils/environments';

import RootLayout from '../RootLayout';

import type { TopLevelNavItem } from '@/app/navigation/types';
import type { ReactNode } from 'react';

export interface DefaultLayoutOldProps {
    children: ReactNode;
    borderless?: boolean;
    transparent?: boolean;
    backLink?: string;
    activeTab?: TopLevelNavItem;
    isReactivation?: boolean;
    isCustomWorkspace?: boolean;
}

const DefaultLayoutOld = ({
    children,
    backLink,
    borderless = false,
    transparent = false,
    activeTab,
    isReactivation = false,
    isCustomWorkspace = false,
}: DefaultLayoutOldProps) => {
    const { canCreate } = useUserCapabilities(CapabilitiesTarget.Workspaces);

    const isWorkspaceEnv = getIsWorkspaceEnv();
    const shouldShowDefaultTopLevelNav =
        !isReactivation && !isCustomWorkspace && !isWorkspaceEnv && canCreate;

    return (
        <RootLayout>
            <Navbar
                borderless={borderless}
                transparent={transparent}
                homeLinkDisabled={isReactivation}
            >
                {backLink && <BackButton to={backLink} />}
                <div className="flex flex-1 items-center justify-end">
                    {shouldShowDefaultTopLevelNav && <TopLevelNav activeTab={activeTab} />}
                    <Avatar />
                </div>
            </Navbar>

            {children}

            {!isReactivation && canCreate && <SupportMenu />}
            {!isReactivation && <CommandMenu />}
        </RootLayout>
    );
};

export default DefaultLayoutOld;
