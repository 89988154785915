import { HAS_SEEN_REFERRAL_DASHBOARD_LS_KEY } from '@/app/referrals/constants';

import {
    ChartBarIcon as ChartBarIconOutline,
    ChartBarSquareIcon as ChartBarSquareIconOutline,
    WrenchScrewdriverIcon as WrenchScrewdriverIconOutline,
    Cog6ToothIcon as Cog6ToothIconOutline,
    GiftIcon as GiftIconOutline,
    HomeIcon as HomeIconOutline,
    PencilSquareIcon as PencilSquareIconOutline,
    SquaresPlusIcon as SquaresPlusIconOutline,
    TrophyIcon as TrophyIconOutline,
    UserIcon as UserIconOutline,
} from '@heroicons/react/24/outline';
import {
    ChartBarIcon,
    ChartBarSquareIcon,
    Cog6ToothIcon,
    GiftIcon,
    HomeIcon,
    PencilSquareIcon,
    SquaresPlusIcon,
    TrophyIcon,
    WrenchScrewdriverIcon,
    UserIcon,
} from '@heroicons/react/24/solid';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

import Tooltip from '@/ui/components/Tooltip';
import { cn } from '@/utils/cn';

import SparklesIcon from './assets/Sparkles.svg';

export interface Props {
    link?: string;
    type: string;
    className?: string;
    active?: boolean;
    mobileHidden?: boolean;
    onClick?: () => void;
}

const NavItem = ({ link, className, type, active, mobileHidden, onClick }: Props) => {
    const { t } = useTranslation('navigation');

    const hasSeenReferralDashboard = localStorage?.getItem(HAS_SEEN_REFERRAL_DASHBOARD_LS_KEY);

    const detailsMap = useMemo(
        () => ({
            home: {
                title: t('home'),
                icon: <HomeIconOutline className="size-5" />,
                activeIcon: <HomeIcon className="size-5" />,
            },
            dashboard: {
                title: t('dashboard'),
                icon: <ChartBarIconOutline className="size-5" />,
                activeIcon: <ChartBarIcon className="size-5" />,
            },
            achievements: {
                title: t('achievements'),
                icon: <TrophyIconOutline className="size-5" />,
                activeIcon: <TrophyIcon className="size-5" />,
            },
            referrals: {
                title: t('referrals'),
                icon: (
                    <div className="relative">
                        {!hasSeenReferralDashboard && (
                            <Image
                                className="absolute left-0 right-0 -translate-x-2 -translate-y-2"
                                src={SparklesIcon}
                                width={18}
                                height={18}
                                alt="sparkles"
                                aria-hidden
                            />
                        )}
                        <GiftIconOutline className="size-5" />
                    </div>
                ),
                activeIcon: <GiftIcon className="size-5" />,
            },
            editor: {
                title: t('editor'),
                icon: <PencilSquareIconOutline className="size-5" />,
                activeIcon: <PencilSquareIcon className="size-5" />,
            },
            analytics: {
                title: t('analytics'),
                icon: <ChartBarSquareIconOutline className="size-5" />,
                activeIcon: <ChartBarSquareIcon className="size-5" />,
            },
            metrics: {
                title: t('metrics'),
                icon: <WrenchScrewdriverIconOutline className="size-5" />,
                activeIcon: <WrenchScrewdriverIcon className="size-5" />,
            },
            settings: {
                title: t('settings'),
                icon: <Cog6ToothIconOutline className="size-5" />,
                activeIcon: <Cog6ToothIcon className="size-5" />,
            },
            integrations: {
                title: t('integrations'),
                icon: <SquaresPlusIconOutline className="size-5" />,
                activeIcon: <SquaresPlusIcon className="size-5" />,
            },
            crm: {
                title: t('crm'),
                icon: <UserIconOutline className="size-5" />,
                activeIcon: <UserIcon className="size-5" />,
            },
            workspaceSettings: {
                title: t('workspace-settings'),
                icon: <Cog6ToothIconOutline className="size-5" />,
            },
        }),
        [hasSeenReferralDashboard, t],
    );

    const content = (
        <Tooltip content={detailsMap[type].title} placement="bottom">
            <button
                className={cn(
                    className,
                    'bump mx-2 size-10 items-center justify-center rounded-lg outline-none',
                    {
                        'bg-blue-50 text-blue-500': active,
                        'text-gray-400 hover:bg-gray-100 hover:text-gray-600': !active,
                        'hidden sm:flex': mobileHidden,
                        flex: !mobileHidden,
                    },
                )}
                onClick={onClick}
                aria-label={detailsMap[type]?.title}
                data-testid={type}
            >
                {active ? detailsMap[type].activeIcon : detailsMap[type].icon}
            </button>
        </Tooltip>
    );

    return link ? <Link href={link}>{content}</Link> : content;
};

export default NavItem;
